// const API_URL = 'http://localhost:4000/api/';
const API_URL = 'https://staging.youcould.gg/api/';
const ENDPOINT_LOGIN = 'auth/login';
const ENDPOINT_CREATE_INVITATION = 'invitation/create';
const ENDPOINT_CREATE_INVITATION_WITH_KEY_GEN =
  'invitation/create-with-key-gen';

export const login = (username: string, password: string) => {
  const form = new FormData();
  form.append('username', username);
  form.append('password', password);

  return fetch(`${API_URL}${ENDPOINT_LOGIN}`, {
    method: 'POST',
    body: form,
  }).then((res) => res.json());
};

export const invite = (email: string, token: string) => {
  return fetch(`${API_URL}${ENDPOINT_CREATE_INVITATION}`, {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json());
};

export const inviteWithKeyGen = (email: string, token: string, key: string) => {
  return fetch(`${API_URL}${ENDPOINT_CREATE_INVITATION_WITH_KEY_GEN}`, {
    method: 'POST',
    body: JSON.stringify({ email, key }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json());
};
