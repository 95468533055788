import { createTheme } from '@mui/material/styles';

import { red } from '@mui/material/colors';
import '@fontsource/outfit/200.css';
import '@fontsource/outfit/300.css';
import '@fontsource/outfit/500.css';
import '@fontsource/montserrat/600.css';

export const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'Outfit'].join(','),
    fontWeightBold: 600,
    fontWeightRegular: 300,
    fontWeightLight: 200,
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export type ThemeType = typeof theme;
