import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import background from './assets/background.png';
import logo from './assets/logo.png';
import star from './assets/star.png';
import plane from './assets/plane.svg';
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  useMediaQuery,
} from '@mui/material';

import { theme } from './theme';
import { useEffect, useState } from 'react';
import { login, inviteWithKeyGen } from './api';

type LoginResponse = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  response: string;
};

type InviteResponse = {
  code: number;
  message: string;
  invitation_code: string;
};

type InviteResponseError = {
  detail: {
    code: number;
    message: string;
  };
};

const OK_RESPONSE_CODE = 20000;

export default function App() {
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [isHandlingLogin, setIsHandlingLogin] = useState(false);
  const [isHandlingInvite, setIsHandlingInvite] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<{ email?: string; error?: string }>({});
  const [inviteResponse, setInviteResponse] = useState<InviteResponse | null>(
    null
  );

  const clearErrors = () => {
    setErrors({});
  };

  const isEmail = (email: string) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const key = new URLSearchParams(window.location.search).get('key') ?? '';

  const validate = () => {
    const errors: { email?: string; other?: string } = {};
    if (!isEmail(email)) {
      errors.email = 'Wrong or invalid email';
      setErrors(errors);
      setIsHandlingInvite(false);
      return;
    } else {
      clearErrors();
    }
  };

  const handleInvite = async () => {
    clearErrors();
    setInviteResponse(null);
    setIsHandlingInvite(true);

    validate();

    try {
      const response: InviteResponse = await inviteWithKeyGen(
        email,
        accessToken,
        key
      );

      if (!response.code || response.code !== OK_RESPONSE_CODE) {
        setErrors({
          ...errors,
          error: (response as unknown as InviteResponseError).detail.message,
        });
        setIsHandlingInvite(false);
        return;
      } else {
        setInviteResponse(response);
      }
      console.log('response => ', response);
    } catch (error: any) {
      setErrors({ ...errors, error: error.message });
    }
    setIsHandlingInvite(false);
  };

  useEffect(() => {
    const handleLogin = async () => {
      clearErrors();

      setInviteResponse(null);
      setIsHandlingLogin(true);
      const { REACT_APP_LOGIN_USERNAME = '', REACT_APP_LOGIN_PASSWORD = '' } =
        process.env;
      try {
        const response: LoginResponse = await login(
          REACT_APP_LOGIN_USERNAME,
          REACT_APP_LOGIN_PASSWORD
        );
        setAccessToken(response?.access_token);
      } catch (error: any) {
        setErrors({ ...errors, error: error.message });
      }
      setIsHandlingLogin(false);
    };

    handleLogin();
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        overflow: 'clip',
        display: 'flex',
        maxHeight: '100vh',
        width: '100vw',
      }}
    >
      <main
        style={{
          flexGrow: 1,
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
          >
            <Container maxWidth="sm">
              <Grid
                container
                xs={12}
                md={12}
                direction="column"
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                {isHandlingLogin ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress size={'8rem'} />
                  </Box>
                ) : (
                  <>
                    <img src={logo} alt="YouCouldLogo" width="100%" />

                    <Typography
                      variant={sm ? 'body2' : md ? 'body1' : 'h6'}
                      color={'white'}
                      gutterBottom
                      alignSelf={'center'}
                      textAlign={'center'}
                      sx={{ marginBottom: '3rem', marginTop: '-1rem' }}
                    >
                      Content defined by you
                    </Typography>

                    <img src={star} alt="YouCouldStar" width="30%" />

                    <Typography
                      fontWeight="light"
                      variant={sm ? 'caption' : 'subtitle1'}
                      color={'white'}
                      sx={{ fontFamily: 'Outfit', marginTop: '3rem' }}
                      gutterBottom
                      alignSelf={'center'}
                      textAlign={'center'}
                    >
                      type in your Mail address and get access to YouCould
                    </Typography>

                    <Grid
                      xs={12}
                      lg={10}
                      container
                      flexDirection={'row'}
                      style={{
                        flexDirection: 'row',
                        background:
                          'linear-gradient(217deg, rgba(95, 224, 227, 1), rgba(139, 101, 236, 1)',
                        padding: 2,
                        borderRadius: 12,
                      }}
                    >
                      <TextField
                        error
                        required
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleInvite();
                          }
                        }}
                        id="filled-required"
                        placeholder="your@mail.com"
                        InputProps={{ disableUnderline: true }}
                        variant="filled"
                        sx={{
                          input: {
                            color: '#ffffff',
                          },
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          flex: 18,
                          backgroundColor: '#202E40',
                          borderRadius: 10,
                        }}
                      />

                      <div
                        role="button"
                        onClick={handleInvite}
                        style={{
                          flex: 2,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {isHandlingInvite ? (
                          <CircularProgress
                            style={{ width: '70%', aspectRatio: 1 }}
                          />
                        ) : (
                          <img
                            src={plane}
                            alt="plane"
                            style={{
                              aspectRatio: 1,
                              width: sm ? '60%' : '50%',
                            }}
                          />
                        )}
                      </div>
                    </Grid>

                    <Grid>
                      {Object.entries(errors).map(([key, error]) => (
                        <div
                          key={`${key}: ${error}`}
                          style={{
                            color: 'red',
                            textAlign: 'center',
                            marginTop: '0.5rem',
                          }}
                        >
                          {key}: {error as string}
                        </div>
                      ))}

                      {inviteResponse?.code === OK_RESPONSE_CODE && (
                        <div
                          style={{
                            color: 'green',
                            textAlign: 'center',
                            marginTop: '0.5rem',
                          }}
                        >
                          {`Congratulations! Your invite code is ${inviteResponse?.invitation_code}`}
                        </div>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </main>
    </div>
  );
}
